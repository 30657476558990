import '../App.css';
import {useForm} from 'react-hook-form';
import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



function Home() {


    const navigate = useNavigate();

  const onSubmit = async (submitedData) => {
    const data1 = {phone, ...submitedData}
      setValue('');
      reset();
   
  
      if (data1.hasOwnProperty('phoneNumber')) {
        delete data1.phoneNumber;
      }
  
      const data = JSON.stringify(data1)
      console.log(data);
  
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      try {
        const response = await axios.post('https://api.silabu.com/api/v1/best-tutors', data, config); 
        
        if (response.status) {
          // Handle success, 
          console.log('Form submitted successfully'); 
        } else {
          // Handle other responses or errors
          console.error('Form submission failed' + response.status);
        }
      } catch (error) {
        // Handle network errors or other exceptions
        console.error('An error occurred:', error);
      }
  
      navigate('/offer');
  
  }  
  
  const schema = yup.object().shape({
      name: yup.string().required('Please, Enter a valid name!').min(4),
      role: yup.string().required('This field is required!'),
      phoneNumber: yup.string(),
      teacherName: yup.string().required('Enter a valid name').min(4),
      teacherSchool: yup.string().required('This field is required!')
  })
  
  
  const {register, handleSubmit, formState: {errors}, reset} = useForm({
    resolver: yupResolver(schema),
  });
  
  
  
  const [value, setValue] = React.useState('');
  
  const phone = value;
  
  
  
    return ( 
    <section className="mb-6 lg:mx-20 text-size-adjust">
       <div className="App"> 
  
         <div className="w-full -mb-4 flex items-center jutify-center lg:mt-5">
           <span className="text-yellow-500 flex-1 -ml-6 -mb-3 lg:-mb-20">&#x2728;</span>
          <span className="text-yellow-500 flex-1">&#x1F506;</span>
          <span className="text-yellow-500 flex-1 -mb-7 z-0 lg:-mb-19">&#x2728;</span> 
        </div>
  
      <div className="flex mx-0 mt-3 text-center">
        <h1 className="text-2xl my-auto text-center sm:text-xl md:text-xl lg:text-3xl font-bold z-1">
          Silabu teacher of the year!
        </h1>
         <img src='https://drive.google.com/uc?id=11NV0qDjL4ZPS2xCsS0q67CDrY37OJIXe' className="h-7 w-6 my-auto"  alt='prize'/> 
      </div>
  
     <div className="container mx-auto px-4 py-4 z-10 lg:mx-20">
        <p className="text-base text-slate-800 sm:text-lg md:text-lg lg:text-xl xl:text-2xl leading-relaxed text-justify">
          Nominate Your Favourite Teacher For "The Silabu Teacher of The Year Award". Teachers With The Toughest Job
          Globally, Deserve Celebration. Complete The Form And Share Your Nomination On Social Media. Highly
          Nominated Teachers Stand A Chance To Win A Brand New BodaBoda From Silabu
        </p>
     </div> </div>
  
     <div className="relative w-full h-20">
       <img src='https://drive.google.com/uc?id=1y-aoq7iNvervDlXYgWcDB4VxkKrbbzt3' height={100} width={170} alt="Bike" className="absolute z-0 right-4 -mt-10 lg:right-40"/> 
     </div>
  
    <div className="items-center justify-center lg:mx-60 lg:mb-10">
      <form onSubmit={handleSubmit(onSubmit)} className="mx-3 my-2 px-3 flex flex-col gap-4">
        <div className="grid w-13">
          <label htmlFor='name' className="font-bold">Your Name</label>
          <input type="text" name="name" id='name' title='Name' autoComplete='true' placeholder='Name' {...register("name")} className="rounded-lg px-2 py-2 text-xl border border-slate-500" />
          {<p className="text-red-500">{errors?.name?.message}</p>}
        </div>
        <div className="flex flex-col w-13">
          <span className="font-bold">I am</span>
          <div className="flex space-x-4">
           <div className="flex space-x-2">
           <input type="radio" name="role" id='person1' title='Person' autoComplete='true' value="Student" {...register("role")} className="border border-slate-500" />
          <label htmlFor='person1' className='text-purple-800'>A Student</label>
           </div>
           <div className="flex space-x-2">
           <input type="radio" name="role" id='person2' title='Person' autoComplete='true' value="Parent" {...register("role")} className="border border-slate-500" />
          <label htmlFor='person2' className='text-purple-800'>A Parent</label>
           </div>
           <div className="flex space-x-2">
           <input type="radio" name="role" id='person3' title='Person' autoComplete='true' value="Teacher" {...register("role")} className="border border-slate-500" />
          <label htmlFor='person3' className='text-purple-800'>A Teacher</label>
           </div>
           </div>  
           {<p className="text-red-500">{errors?.person?.message}</p>}
        </div>
        <div className="flex flex-col w-13">
          <label htmlFor='phoneNumber' className="font-bold">Phone Number</label>
          <div className="rounded-lg px-2 py-2 text-xl border border-slate-500">
          <PhoneInput {...register("phoneNumber")} value={value} onChange={setValue} placeholder="Enter phone number" 
                  defaultCountry="TZ" 
                   id='phoneNumber'
                   title='Phone Number'
                   autoComplete='true' required
                   className="rounded-lg text-lg border-none focus:border-none"
                   />
          <span>{value}</span>         
          </div>
          {<p className="text-red-500">{errors?.phoneNumber?.message}</p>}
        </div>
        <div className="flex flex-col w-13">
          <label htmlFor='candidate' className="font-bold">Who should win?(Teacher's Name)</label>
          <input type="text"  name="teacherName" id='teacherName' title='Candidate' autoComplete='true' placeholder='Candidate' {...register("teacherName")} className="rounded-lg px-2 py-2 text-xl border border-slate-500" />
          {<p className="text-red-500">{errors?.candidate?.message}</p>}
        </div>
        <div className="flex flex-col w-13">
          <label htmlFor='school' className="font-bold">Which school does the teacher teach?</label>
          <input type="text"  name="teacherSchool" id='teacherSchool' title='School' autoComplete='true' placeholder='School' {...register("teacherSchool")} className="rounded-lg px-2 py-2 text-xl border border-slate-500" />
          {<p className="text-red-500">{errors?.school?.message}</p>}
        </div>
        <button type="submit" className="rounded text-white bg-purple-900 hover:bg-purple-800 w-13 h-10 text-lg">Submit</button>
      </form> 
    </div> 
      </section>
      );
  }
  
  export default Home;
   