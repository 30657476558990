import React from "react";
import './Offer.css';
import {Link} from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs';
import PopupModal from "./PopupModal";


const Offer = () => {

    const [isOpen, setIsOpen] = React.useState(false);

    const onClose = () => {
        setIsOpen(true);
    }

    return (
        <div className="App lg:mx-60 md:mx-60">
     <Link to="/" className="fixed top-0 left-0 m-4 text-4xl text-blue-500 z-10">
         <BsArrowLeft />
    </Link>
          <div className="text-center my-8">
          <img src="https://drive.google.com/uc?id=1DfjzCi5Ou3OQUlnWMxNhMQOR-p2eDlKN" alt="ModalImage" className="mx-auto rounded-full w-16 h-16" />
        </div>
            <h2 className="text-xl font-bold mt-5 mb-4 text-center">Thank You!</h2>
                <p className="mt-2 text-base text-slate-800 sm:text-lg md:text-lg lg:text-xl xl:text-2xl leading-relaxed text-justify sm:px-3">
                We appreciate you taking time to help us improve the quality of education in return we offer you
                <span className='font-black'> TZS 10 000</span> to book any class or tutor on Silabu. Install Silabu 
                app and use the promo code <span className='font-black'>GIFT</span> when booking a tutor
                </p> 
        
            <div className='text-center my-5'>
            <span className='text-sm'>Offer valid for 3 Days</span>
            </div>
        <div className='text-center my-3'>
        <button onClick={() => setIsOpen(true)} className='bg-purple-800 px-7 py-2 text-white rounded-lg font-bold'>INSTALL SILABU APP</button>
        </div>
    <PopupModal isOpen={isOpen} onClose={onClose} />    
        </div>
    );
}

export default Offer;