

import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const PopupModal = ({ isOpen, onClose }) => {

  return (
    <Modal
           isOpen={isOpen}
           onRequestClose={onClose}
           contentLabel="Popup Modal"
           className="bg-white p-4 w-full max-w-md grid fixed bottom-0 left-0 right-0 h-7/8 rounded-t-lg lg:m-auto"
         >


    <a className='rounded-lg bg-purple-800 px-7 py-2 text-white text-center rounded-lg font-bold m-3' href='https://apps.apple.com/us/app/silabu/id1628579393'>App Store</a>
    <a className='rounded-lg bg-purple-800 px-7 py-2 text-white text-center rounded-lg font-bold m-3' href='https://play.google.com/store/apps?device=tablet'>Play Store</a>
    </Modal> 
  );
};

export default PopupModal;


// https://drive.google.com/file/d/1DfjzCi5Ou3OQUlnWMxNhMQOR-p2eDlKN/view?usp=sharing