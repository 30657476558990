
import './App.css';
import Home from './Components/Home';
import Offer from './Components/Offer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {

  
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}/>
        <Route path="/offer" element={<Offer />}/>
      </Routes>
    </Router>
  );

} 

export default App;
 